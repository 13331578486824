<template>
  <ValidationObserver slim v-slot="{ invalid, untouched, handleSubmit }">
    <v-dialog
        :value="showDialog"
        :persistent="false"
        width="600"
        @input="closeDialog"
    >
      <v-card>
        <v-card-title class="headline">
          <span>
           <i class="fa" :class="{'fa-plus-square-o': !currentItem, 'fa-pencil-square-o': currentItem}" ></i>
            {{ currentItem ? 'Редактирование товара для СП' : 'Добавление товара для СП' }}
          </span>
        </v-card-title>

        <v-card-text>
          <form>
            <v-layout column>
              <ValidationProvider v-if="!id" name="structural_subdivision_id" rules="required" v-slot="{invalid, validated}">
                <v-select
                    v-model="structural_subdivision_id"
                    :items="subdivisionWithProductsList"
                    item-value="id"
                    item-text="name"
                    label="Выбрать структурное подразделение *"
                    hide-details="auto"
                    required
                    :error="invalid && validated"
                ></v-select>
              </ValidationProvider>
              <ValidationProvider v-if="!id" name="product_id" rules="required" v-slot="{invalid, validated}">
                <v-select
                  v-model="product_id"
                  :items="productsList"
                  item-value="id"
                  item-text="name"
                  label="Выбрать товар *"
                  hide-details="auto"
                  required
                  :error="invalid && validated"
                >
                  <template
                    slot="item"
                    slot-scope="data"
                  >
                    <!-- Divider and Header-->
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item"/>
                    </template>
                    <!-- Normal item -->
                    <template v-else>
                      <v-list-item-content class="ml-5">
                        <v-list-item-title v-html="data.item.name"/>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-select>
              </ValidationProvider>
              <ValidationProvider name="structural_subdivision_balance" rules="required|numeric|min_value:0" v-slot="{invalid, validated}">
                <v-text-field
                    :error="invalid && validated"
                    label="Баланс *"
                    hide-details="auto"
                    type="number"
                    min="1"
                    step="1"
                    v-model="structural_subdivision_balance"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider rules="required|numeric|min_value:1" name="minimum_count_for_order" v-slot="{invalid, validated}">
                <v-text-field
                    :error="invalid && validated"
                    label="Минимальное количество для заказа *"
                    hide-details="auto"
                    type="number"
                    min="1"
                    step="1"
                    v-model="minimum_count_for_order"
                ></v-text-field>
              </ValidationProvider>
            </v-layout>
          </form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <CButton class="mr-3" color="secondary" @click="$emit('close')">Отмена</CButton>
          <CButton :disabled="invalid" @click="handleSubmit(submit)" color="success">
            <i class="fa fa-save mr-2"></i>
            <span>Сохранить</span>
          </CButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ValidationObserver>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import EntityModalMixin from '@/views/grid_elements/mixins/EntityModalMixin';
import api from '@/api';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: "subdivision-product-modal",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  mixins: [EntityModalMixin],
  created() {
    this.fetchSubdivisionWithProductsList();
    this.fetchCategoriesWithProductsList();
  },
  data() {
    let state = {
      id: null,
      structural_subdivision_id: null,
      product_id: null,
      structural_subdivision_balance: null,
      minimum_count_for_order: null,
      name: '',
      email_for_notification: '',
    };

    if (this.currentItem) {
      state = {
        ...state,
        ...this.currentItem
      }
    }

    // console.log(state);

    return state;
  },
  methods: {
    ...mapActions('structuralsubdivisions', ['fetchSubdivisionWithProductsList']),
    ...mapActions('catalog', ['fetchCategoriesWithProductsList']),
    createEntity: api.structural_subdivisions.createProduct,
    updateEntity: api.structural_subdivisions.updateProduct,
  },
  computed: {
    ...mapGetters('structuralsubdivisions', ['subdivisionWithProductsList']),
    ...mapGetters('catalog', ['categoriesWithProductsList']),
    data() {
      return {
        id: this.id,
        structural_subdivision_id: this.structural_subdivision_id,
        product_id: this.product_id,
        structural_subdivision_balance: this.structural_subdivision_balance,
        minimum_count_for_order: this.minimum_count_for_order
      }
    },
    productsList() {
      const subdivisionId = this.structural_subdivision_id;
      let products;

      if (subdivisionId) {
        const subdivision = this.subdivisionWithProductsList.find(({id}) => id === subdivisionId);

        if (subdivision) {
          products = subdivision.products
        }
      }

      const filter = [];

      this.categoriesWithProductsList.forEach((category, key) => {
        filter.push({header: category.name});

        category.products.forEach((product, key) => {
          let disabled = false;

          if (products && products.length) {
            disabled = products.some(({id}) => id === product.id)
          }

          filter.push({id: product.id, name: product.name, group: category.name, disabled})
        });
      });

      console.log(products);
      console.log(filter);

      return filter;
    }
  }
}
</script>
