var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var untouched = ref.untouched;
var handleSubmit = ref.handleSubmit;
return [_c('v-dialog',{attrs:{"value":_vm.showDialog,"persistent":false,"width":"600"},on:{"input":_vm.closeDialog}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_c('span',[_c('i',{staticClass:"fa",class:{'fa-plus-square-o': !_vm.currentItem, 'fa-pencil-square-o': _vm.currentItem}}),_vm._v(" "+_vm._s(_vm.currentItem ? 'Редактирование товара для СП' : 'Добавление товара для СП')+" ")])]),_c('v-card-text',[_c('form',[_c('v-layout',{attrs:{"column":""}},[(!_vm.id)?_c('ValidationProvider',{attrs:{"name":"structural_subdivision_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('v-select',{attrs:{"items":_vm.subdivisionWithProductsList,"item-value":"id","item-text":"name","label":"Выбрать структурное подразделение *","hide-details":"auto","required":"","error":invalid && validated},model:{value:(_vm.structural_subdivision_id),callback:function ($$v) {_vm.structural_subdivision_id=$$v},expression:"structural_subdivision_id"}})]}}],null,true)}):_vm._e(),(!_vm.id)?_c('ValidationProvider',{attrs:{"name":"product_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('v-select',{attrs:{"items":_vm.productsList,"item-value":"id","item-text":"name","label":"Выбрать товар *","hide-details":"auto","required":"","error":invalid && validated},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(typeof data.item !== 'object')?[_c('v-list-item-content',{domProps:{"textContent":_vm._s(data.item)}})]:[_c('v-list-item-content',{staticClass:"ml-5"},[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.name)}})],1)]]}}],null,true),model:{value:(_vm.product_id),callback:function ($$v) {_vm.product_id=$$v},expression:"product_id"}})]}}],null,true)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"structural_subdivision_balance","rules":"required|numeric|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('v-text-field',{attrs:{"error":invalid && validated,"label":"Баланс *","hide-details":"auto","type":"number","min":"1","step":"1"},model:{value:(_vm.structural_subdivision_balance),callback:function ($$v) {_vm.structural_subdivision_balance=$$v},expression:"structural_subdivision_balance"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|numeric|min_value:1","name":"minimum_count_for_order"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('v-text-field',{attrs:{"error":invalid && validated,"label":"Минимальное количество для заказа *","hide-details":"auto","type":"number","min":"1","step":"1"},model:{value:(_vm.minimum_count_for_order),callback:function ($$v) {_vm.minimum_count_for_order=$$v},expression:"minimum_count_for_order"}})]}}],null,true)})],1)],1)]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('CButton',{staticClass:"mr-3",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Отмена")]),_c('CButton',{attrs:{"disabled":invalid,"color":"success"},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_c('i',{staticClass:"fa fa-save mr-2"}),_c('span',[_vm._v("Сохранить")])])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }