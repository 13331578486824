<template>
    <div style="display: flex; flex-direction: column">
        <v-row class="table-actions-row">
            <v-col sm="4" cols="12" class="pl-0">
                <breadcrumbs :items="crumbs" :exact="true"> </breadcrumbs>
            </v-col>
        </v-row>
        <localization
            style="display: flex; flex-direction: column"
            :language="'ru-RU'"
        >
            <intl style="display: flex; flex-direction: column" :locale="'ru'">
                <Grid
                    class="subdivision-products"
                    ref="grid"
                    :scrollable="true"
                    :sortable="true"
                    :filterable="true"
                    :pageable="gridPageable"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    @filterchange="filterChange"
                    :take="take"
                    :skip="skip"
                    :sort="sort"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    :expand-field="'expanded'"
                    @expandchange="expandChange"
                    @sortchange="sortChange"
                >
                    <grid-toolbar class="justify-content-end">
                        <CButton
                            color="danger"
                            class="d-block"
                            :disabled="!groupDeletion.length"
                            @click="deleteEntities(groupDeletion)"
                        >
                            <i class="la la-trash"></i>
                            Удалить
                        </CButton>
                        <CButton
                            class="d-block toolbox-btn toolbox-btn-green"
                            @click="showProductUploadModal = true;"
                        >
                            <i class="la la-upload"></i>
                            Загрузка товаров
                        </CButton>
                        <CButton
                            @click="
                                clearCurrentItem();
                                showEntityModal = true;
                            "
                            class="d-block"
                            color="info"
                        >
                            <i class="la la-plus"></i>
                            Добавить товар
                        </CButton>
                    </grid-toolbar>
                    <template v-slot:selection_all>
                        <v-checkbox
                            :input-value="includeDeletionProducts.length && includeDeletionProducts.length === dataResult.data.length"
                            :value="includeDeletionProducts.length && includeDeletionProducts.length === dataResult.data.length"
                            class="mt-0 pt-0"
                            color="info"
                            hide-details
                            @change="selectAllProductsHandle"
                        />
                    </template>
                    <template v-slot:actions="{ props: { dataItem } }">
                        <td>
                            <div class="actionsColumn">
                                <actions-dropdown
                                    :actions="getActions(dataItem)"
                                />
                            </div>
                        </td>
                    </template>
                    <template v-slot:selection="{ props: { dataItem } }">
                        <td>
                            <v-simple-checkbox
                                :value="!!groupDeletion.find((deletionId) => dataItem.id === deletionId)"
                                class="d-flex justify-center"
                                color="info"
                                @input="selectProductHandle(dataItem.id)"
                            />
                        </td>
                    </template>
                </Grid>
            </intl>
        </localization>
        <subdivision-product-modal
            v-if="showEntityModal"
            :current-item="currentItem"
            :show-dialog="showEntityModal"
            @click:outside="showEntityModal = false"
            @stored="entityStored($event)"
            @close="showEntityModal = false"
        ></subdivision-product-modal>
        <move-balance-modal
            v-if="showMoveBalanceModal"
            :current-item="currentItem"
            :show-dialog="showMoveBalanceModal"
            @click:outside="showMoveBalanceModal = false"
            @stored="
                showMoveBalanceModal = false;
                updateTable();
            "
            @close="showMoveBalanceModal = false"
        ></move-balance-modal>
        <upload-products-modal
            v-if="showProductUploadModal"
            :current-item="currentItem"
            :show-dialog="showProductUploadModal"
            @stored="
                showProductUploadModal = false;
                updateTable();
            "
            @close="showProductUploadModal = false"
        ></upload-products-modal>
    </div>
</template>

<script>
    import TableMixin from '@/views/grid_elements/mixins/TableMixin';
    import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
    import {
        IntlProvider,
        LocalizationProvider,
    } from '@progress/kendo-vue-intl';
    import ActionsDropdown from '@/views/grid_elements/ActionsDropdown';
    import { showConfirm } from '@/lib/swal2';
    import { showMessage } from '@/lib/toasted';
    import Breadcrumbs from '@/views/components/breadcrumbs';
    import '@/views/grid_elements/translations';
    import api from '@/api';
    import { mapActions, mapGetters } from 'vuex';
    import SubdivisionProductModal from '../modals/SubdivisionProductCreateUpdateModal';
    import MoveBalanceModal from '../modals/MoveSubdivisionProductBalanceModal';
    import UploadProductsModal from '../modals/UploadSubdivisionProductsModal';
    import { toDataSourceRequestString } from '@progress/kendo-data-query';

    export default {
        name: 'subdivision-products',
        components: {
            Grid: Grid,
            breadcrumbs: Breadcrumbs,
            'subdivision-product-modal': SubdivisionProductModal,
            'move-balance-modal': MoveBalanceModal,
            'upload-products-modal': UploadProductsModal,
            localization: LocalizationProvider,
            intl: IntlProvider,
            ActionsDropdown,
            GridToolbar,
        },
        mixins: [TableMixin],
        async created() {
            await this.fetchSubdivisionListShort();
            await this.getData();
        },
        data() {
            return {
                skip: 0,
                take: 10,
                pageSize: 10,
                total: 7,
                gridPageable: { pageSizes: true },
                groupDeletion: [],
                columns: [
                    {
                        field: '',
                        title: '',
                        cell: 'selection',
                        sortable: false,
                        width: '40px',
                        filterCell: 'selection_all',
                    },
                    {
                        field: 'id',
                        title: 'Действия',
                        cell: 'actions',
                        filterable: false,
                        sortable: false,
                        width: '80px'
                    },
                    {
                        field: 'structural_subdivision_name',
                        title: 'Структурное подразделение',
                        type: 'text',
                        filter: 'text',
                        // width: '50%'
                    },
                    {
                        field: 'product_name',
                        title: 'Название',
                        width: '300px'
                    },
                    {
                        field: 'structural_subdivision_balance',
                        title: 'Баланс',
                        type: 'numeric',
                        filter: 'numeric',
                        width: '100px'
                    },
                    {
                        field: 'minimum_count_for_order',
                        title: 'Минимальное количество для заказа',
                        type: 'numeric',
                        filter: 'numeric',
                        width: '100px'
                    },
                    {
                        field: 'change_date',
                        title: 'Дата изменения',
                        filter: 'date',
                        format: '{0:d}',
                        width: '100px'
                    },
                ],
                showMoveBalanceModal: false,
                showProductUploadModal: false,
            };
        },
        methods: {
            ...mapActions('structuralsubdivisions', [
                'fetchSubdivisionListShort',
            ]),
            fetchData: api.structural_subdivisions.getProducts,
            getActions(dataItem) {
                const actions = [
                    {
                        text: 'Редактировать',
                        icon: 'la la-pencil',
                        handler: () => {
                            this.setCurrentItem(dataItem);
                            this.showEntityModal = true;
                        },
                    },
                ];

                if (dataItem.structural_subdivision_balance) {
                    actions.push({
                        text: 'Переместить',
                        icon: 'la la-arrow-right',
                        handler: () => {
                            this.setCurrentItem(dataItem);
                            this.showMoveBalanceModal = true;
                        },
                    });
                }

                actions.push({
                    text: 'Удалить',
                    icon: 'la la-trash',
                    handler: () => this.deleteEntities([dataItem.id]),
                });

                return actions;
            },
            selectProductHandle(productId) {
                const isExistsProductsId = this.groupDeletion.some(
                    (deletionId) => deletionId === productId,
                )
                if (!isExistsProductsId) {
                    this.groupDeletion.push(productId)
                } else {
                    this.groupDeletion = this.groupDeletion.filter(
                        (deletionId) => deletionId !== productId
                    )
                }
            },
            selectAllProductsHandle() {
                if (this.includeDeletionProducts.length === this.dataResult.data.length) {
                    this.groupDeletion = this.groupDeletion.filter(
                        (deletionId) => !this.includeDeletionProducts.find(
                            (product) => product.id === deletionId
                        )
                    )
                } else {
                    this.groupDeletion = [
                        ...this.groupDeletion,
                        ...this.dataResult.data.map((product) => product.id)
                    ]
                }
            },
            async deleteEntities(arrayOfDeletionProducts) {
                const { isConfirmed } = await showConfirm(
                    'Вы действительно хотите удалить данный элемент?'
                );

                if (isConfirmed) {
                    const {
                        data: { status, message },
                    } = await api.structural_subdivisions.deleteProduct(arrayOfDeletionProducts);
                    if (this.groupDeletion.length === this.dataResult.data.length) {
                      this.updateTable(false, true)
                    } else {
                      this.updateTable()
                    }
                    if (this.groupDeletion.length) {
                        this.groupDeletion = []
                    }
                    showMessage(status, message);
                }
            },
            prepareFetchParams() {
                return [
                    this.page,
                    this.take,
                    toDataSourceRequestString({
                        filter: this.filter,
                        sort: this.sort,
                    }),
                ];
            },
            selectSubdivision({ value }) {
                if (value.id) {
                    this.setFilter('structural_subdivision_id', 'eq', value.id);
                } else {
                    this.removeFilter('structural_subdivision_id');
                }

                this.updateTable(true);
            },
            expandChange(event) {
                this.$set(
                    event.dataItem,
                    event.target.$props.expandField,
                    event.value
                );
            },
        },
        computed: {
            ...mapGetters('structuralsubdivisions', ['subdivisionListShort']),
            includeDeletionProducts() {
                return this.dataResult.data.filter(
                    (dataResult) => this.groupDeletion.includes(dataResult.id)
                )
            },
        },
    };
</script>

<style scoped></style>
