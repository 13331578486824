var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('v-row',{staticClass:"table-actions-row"},[_c('v-col',{staticClass:"pl-0",attrs:{"sm":"4","cols":"12"}},[_c('breadcrumbs',{attrs:{"items":_vm.crumbs,"exact":true}})],1)],1),_c('localization',{staticStyle:{"display":"flex","flex-direction":"column"},attrs:{"language":'ru-RU'}},[_c('intl',{staticStyle:{"display":"flex","flex-direction":"column"},attrs:{"locale":'ru'}},[_c('Grid',{ref:"grid",staticClass:"subdivision-products",attrs:{"scrollable":true,"sortable":true,"filterable":true,"pageable":_vm.gridPageable,"filter":_vm.filter,"take":_vm.take,"skip":_vm.skip,"sort":_vm.sort,"page-size":_vm.pageSize,"total":_vm.total,"data-items":_vm.dataResult,"columns":_vm.columns,"expand-field":'expanded'},on:{"datastatechange":_vm.dataStateChange,"filterchange":_vm.filterChange,"expandchange":_vm.expandChange,"sortchange":_vm.sortChange},scopedSlots:_vm._u([{key:"selection_all",fn:function(){return [_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"input-value":_vm.includeDeletionProducts.length && _vm.includeDeletionProducts.length === _vm.dataResult.data.length,"value":_vm.includeDeletionProducts.length && _vm.includeDeletionProducts.length === _vm.dataResult.data.length,"color":"info","hide-details":""},on:{"change":_vm.selectAllProductsHandle}})]},proxy:true},{key:"actions",fn:function(ref){
var dataItem = ref.props.dataItem;
return [_c('td',[_c('div',{staticClass:"actionsColumn"},[_c('actions-dropdown',{attrs:{"actions":_vm.getActions(dataItem)}})],1)])]}},{key:"selection",fn:function(ref){
var dataItem = ref.props.dataItem;
return [_c('td',[_c('v-simple-checkbox',{staticClass:"d-flex justify-center",attrs:{"value":!!_vm.groupDeletion.find(function (deletionId) { return dataItem.id === deletionId; }),"color":"info"},on:{"input":function($event){return _vm.selectProductHandle(dataItem.id)}}})],1)]}}])},[_c('grid-toolbar',{staticClass:"justify-content-end"},[_c('CButton',{staticClass:"d-block",attrs:{"color":"danger","disabled":!_vm.groupDeletion.length},on:{"click":function($event){return _vm.deleteEntities(_vm.groupDeletion)}}},[_c('i',{staticClass:"la la-trash"}),_vm._v(" Удалить ")]),_c('CButton',{staticClass:"d-block toolbox-btn toolbox-btn-green",on:{"click":function($event){_vm.showProductUploadModal = true;}}},[_c('i',{staticClass:"la la-upload"}),_vm._v(" Загрузка товаров ")]),_c('CButton',{staticClass:"d-block",attrs:{"color":"info"},on:{"click":function($event){_vm.clearCurrentItem();
                            _vm.showEntityModal = true;}}},[_c('i',{staticClass:"la la-plus"}),_vm._v(" Добавить товар ")])],1)],1)],1)],1),(_vm.showEntityModal)?_c('subdivision-product-modal',{attrs:{"current-item":_vm.currentItem,"show-dialog":_vm.showEntityModal},on:{"click:outside":function($event){_vm.showEntityModal = false},"stored":function($event){return _vm.entityStored($event)},"close":function($event){_vm.showEntityModal = false}}}):_vm._e(),(_vm.showMoveBalanceModal)?_c('move-balance-modal',{attrs:{"current-item":_vm.currentItem,"show-dialog":_vm.showMoveBalanceModal},on:{"click:outside":function($event){_vm.showMoveBalanceModal = false},"stored":function($event){_vm.showMoveBalanceModal = false;
            _vm.updateTable();},"close":function($event){_vm.showMoveBalanceModal = false}}}):_vm._e(),(_vm.showProductUploadModal)?_c('upload-products-modal',{attrs:{"current-item":_vm.currentItem,"show-dialog":_vm.showProductUploadModal},on:{"stored":function($event){_vm.showProductUploadModal = false;
            _vm.updateTable();},"close":function($event){_vm.showProductUploadModal = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }