<template>
  <ValidationObserver slim v-slot="{ invalid, untouched, handleSubmit }">
    <v-dialog
        :value="showDialog"
        :persistent="false"
        width="600"
        @input="closeDialog"
    >
      <v-card>
        <v-card-title class="headline">
          <span><i class="la la-arrow-right"></i> Перенос баланса</span>
        </v-card-title>

        <v-card-text>
          <form>
            <v-layout column>
              <v-text-field
                  label="От структурного подразделения *"
                  hide-details="auto"
                  :disabled="true"
                  v-model="structural_subdivision_name"
              ></v-text-field>
              <v-text-field
                  label="Название продукта *"
                  hide-details="auto"
                  :disabled="true"
                  v-model="product_name"
              ></v-text-field>
              <ValidationProvider name="to_subdivision" rules="required" v-slot="{invalid, validated}">
                <v-select
                    v-model="to_subdivision"
                    :items="subdivisionList"
                    item-value="id"
                    item-text="name"
                    label="К структурному подразделению *"
                    hide-details="auto"
                    required
                    :error="invalid && validated"
                >
                </v-select>
              </ValidationProvider>
              <ValidationProvider name="balance_to_transfer"
                                  :rules="`required|min_value:1|max_value:${structural_subdivision_balance}`"
                                  v-slot="{invalid, validated}">
                <v-text-field
                    :error="invalid && validated"
                    label="Баланс *"
                    persistent-hint
                    :hint="`Максимальный баланс для переноса ${structural_subdivision_balance}`"
                    type="number"
                    :max="structural_subdivision_balance"
                    min="1"
                    step="1"
                    v-model="balance_to_transfer"
                ></v-text-field>
              </ValidationProvider>
            </v-layout>
          </form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <CButton class="mr-3" color="secondary" @click="$emit('close')">Отмена</CButton>
          <CButton :disabled="invalid" @click="handleSubmit(submit)" color="success">
            <i class="fa fa-save mr-2"></i>
            <span>Сохранить</span>
          </CButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ValidationObserver>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import EntityModalMixin from '@/views/grid_elements/mixins/EntityModalMixin';
import api from '@/api';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: "move-subdivision-product-modal",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  mixins: [EntityModalMixin],
  created() {
    this.fetchSubdivisionListShort();
  },
  data() {
    let state = {
      id: null,
      structural_subdivision_id: null,
      structural_subdivision_name: null,
      product_name: null,
      to_subdivision: null,
      balance_to_transfer: null,
      structural_subdivision_balance: null,
      minimum_count_for_order: null,
    };

    if (this.currentItem) {
      state = {
        ...state,
        ...this.currentItem
      }
    }

    return state;
  },
  methods: {
    ...mapActions('structuralsubdivisions', ['fetchSubdivisionListShort']),
    updateEntity: api.structural_subdivisions.moveBalance,
  },
  computed: {
    ...mapGetters('structuralsubdivisions', ['subdivisionListShort']),
    data() {
      return {
        id: this.id,
        structural_subdivision_id: this.to_subdivision,
        structural_subdivision_balance: this.balance_to_transfer
      }
    },
    subdivisionList() {
      return this.subdivisionListShort.filter((subdivision) => {
        return subdivision.id !== this.structural_subdivision_id;
      });
    }
  }
}
</script>
