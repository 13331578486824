<template>
  <v-dialog width="800" id="product-image-picker-modal" class="product-attachment-modal" :value="showDialog">
    <v-card>
      <v-card-title class="headline">
        <span><i class="fa fa-file"></i> Загрузка товаров для структурного подразделения</span>
      </v-card-title>

      <v-card-text>
        <form class="m-form m-form--state">
          <div>
            <p> Выберите файл</p>
            <input @change="fileUpload" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
          </div>
        </form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton class="mr-3" color="secondary" @click="$emit('close')">Отмена</CButton>
        <CButton :disabled="!file" @click="submit" color="success"><i class="fa fa-save"></i> <span>Загрузить</span></CButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { serialize } from 'object-to-formdata';
import api from "@/api";
import {showMessage} from "@/lib/toasted";

export default {
  name: "upload-subdivision-products-modal",
  props: {
    showDialog: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      file: null
    }
  },
  methods: {
    fileUpload(e) {
      let files = e.target.files || e.dataTransfer.files;

      if (files.length) {
        this.file = files[0];
      }
    },
    async submit() {
      const file = this.file;
      if (file) {
        const {data: {status, message}} = await api.structural_subdivisions.uploadProducts(serialize({
          file
        }));

        showMessage(status, message);

        this.$emit('stored');
      }
    }
  }
}
</script>

<style scoped>

</style>
