var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var untouched = ref.untouched;
var handleSubmit = ref.handleSubmit;
return [_c('v-dialog',{attrs:{"value":_vm.showDialog,"persistent":false,"width":"600"},on:{"input":_vm.closeDialog}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_c('span',[_c('i',{staticClass:"la la-arrow-right"}),_vm._v(" Перенос баланса")])]),_c('v-card-text',[_c('form',[_c('v-layout',{attrs:{"column":""}},[_c('v-text-field',{attrs:{"label":"От структурного подразделения *","hide-details":"auto","disabled":true},model:{value:(_vm.structural_subdivision_name),callback:function ($$v) {_vm.structural_subdivision_name=$$v},expression:"structural_subdivision_name"}}),_c('v-text-field',{attrs:{"label":"Название продукта *","hide-details":"auto","disabled":true},model:{value:(_vm.product_name),callback:function ($$v) {_vm.product_name=$$v},expression:"product_name"}}),_c('ValidationProvider',{attrs:{"name":"to_subdivision","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('v-select',{attrs:{"items":_vm.subdivisionList,"item-value":"id","item-text":"name","label":"К структурному подразделению *","hide-details":"auto","required":"","error":invalid && validated},model:{value:(_vm.to_subdivision),callback:function ($$v) {_vm.to_subdivision=$$v},expression:"to_subdivision"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"balance_to_transfer","rules":("required|min_value:1|max_value:" + _vm.structural_subdivision_balance)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('v-text-field',{attrs:{"error":invalid && validated,"label":"Баланс *","persistent-hint":"","hint":("Максимальный баланс для переноса " + _vm.structural_subdivision_balance),"type":"number","max":_vm.structural_subdivision_balance,"min":"1","step":"1"},model:{value:(_vm.balance_to_transfer),callback:function ($$v) {_vm.balance_to_transfer=$$v},expression:"balance_to_transfer"}})]}}],null,true)})],1)],1)]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('CButton',{staticClass:"mr-3",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Отмена")]),_c('CButton',{attrs:{"disabled":invalid,"color":"success"},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_c('i',{staticClass:"fa fa-save mr-2"}),_c('span',[_vm._v("Сохранить")])])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }